import React from 'react';
import { Helmet } from 'react-helmet';
import ProjectApp from '../components/ProjectApp';
import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

import { giorgioData } from '../mock/projectData';

export default () => {
    const { title, lang, description } = headData;
    const { heroImageData, aboutData, detailData } = giorgioData;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title || 'Gatsby Simplefolio'}</title>
                <html lang={lang || 'en'} />
                <meta name="description" content={description || 'Gatsby Simplefolio'} />
            </Helmet>
            {/* Better format: <ProjectImg alt={title} filename={img} /> */}
            {ProjectApp(heroImageData, aboutData, detailData)}
        </>
    );
};
